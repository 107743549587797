<template>
  <CountdownTimer />
</template>

<script>
import CountdownTimer from './components/CountdownTimer.vue';

export default {
  name: 'App',
  components: {
    CountdownTimer,
  },
};
</script>