<template>
  <div class="min-h-actual-screen flex items-center justify-center bg-black text-green-500 font-mono">
    <div class="text-center">
      <div class="flex flex-col md:flex-row gap-8 justify-center text-5xl md:text-7xl">
        <div class="flex space-x-8 md:space-x-8 justify-center">
          <div class="flex-1 flex flex-col items-center">
            <span class="font-bold">{{ months }}</span>
            <span class="text-sm">Months</span>
          </div>
          <div class="flex-1 flex flex-col items-center">
            <span class="font-bold">{{ weeks }}</span>
            <span class="text-sm">Weeks</span>
          </div>
          <div class="flex-1 flex flex-col items-center">
            <span class="font-bold">{{ days }}</span>
            <span class="text-sm">Days</span>
          </div>
        </div>
        <div class="flex space-x-8 md:space-x-8 justify-center">
          <div class="flex-1 flex flex-col items-center">
            <span class="font-bold">{{ hours }}</span>
            <span class="text-sm">Hours</span>
          </div>
          <div class="flex-1 flex flex-col items-center">
            <span class="font-bold">{{ minutes }}</span>
            <span class="text-sm">Minutes</span>
          </div>
          <div class="flex-1 flex flex-col items-center">
            <span class="font-bold">{{ seconds }}</span>
            <span class="text-sm">Seconds</span>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountdownTimer',
  data() {
    return {
      targetDate: new Date('2025-04-01T10:00:00'), // Your target date and time
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  mounted() {
    this.calculateCountdown();
    setInterval(this.calculateCountdown, 1000); // Update every second
  },
  methods: {
    calculateCountdown() {
      const now = new Date();
      const difference = this.targetDate - now;

      if (difference > 0) {
        const totalSeconds = Math.floor(difference / 1000);
        const totalMinutes = Math.floor(totalSeconds / 60);
        const totalHours = Math.floor(totalMinutes / 60);
        const totalDays = Math.floor(totalHours / 24);

        this.months = Math.floor(totalDays / 30);
        const remainingDays = totalDays % 30;
        this.weeks = Math.floor(remainingDays / 7);
        this.days = remainingDays % 7;

        this.hours = totalHours % 24;
        this.minutes = totalMinutes % 60;
        this.seconds = totalSeconds % 60;
      } else {
        // Set all values to 0 if the event is in the past
        this.months = this.weeks = this.days = this.hours = this.minutes = this.seconds = 0;
      }
    },
  },
};
</script>
